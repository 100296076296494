// import React, { Component } from 'react';
// import PhoneInput from 'react-phone-input-2';
// import axios from 'axios';
// import $ from 'jquery';
// import jQuery from 'jquery';
// import "./userManagement.css";
// // import PersonMaster from '../../Master/PersonMaster/PersonMaster.js';

// class EditUserProfile extends Component {
//     constructor(props) {
//         super(props);

//         // Check if props.match is available and has params
//         const { match } = props;
//         const UserId = match && match.params ? match.params.id : '';

//         this.state = {
//             UserId,
//             fullname: "",
//             username: "",
//             mobNumber: "",
//             userProfile: "",
//             firstName: "",
//             lastName: "",
//             // entity: entity, // Assuming entity is defined elsewhere
//             centerName: "",
//             user: "user",
//             // gmapsLoaded: false,
//         };

//         this.handleChange = this.handleChange.bind(this);
//     }

//     // Other code...

//     componentDidMount() {
//         const { match } = this.props;
//         const UserId = match && match.params ? match.params.id : ''; // Get the id from the URL

//         // Rest of your code...

//         this.userdata(UserId); // Pass the UserId to the function to fetch user data
//     }

//     userdata(UserId) {
//         axios.get(`/api/users/get/${UserId}`)
//             .then((res) => {
//                 this.setState({
//                     firstName: res.data.firstname,
//                     lastName: res.data.lastname,
//                     username: res.data.email,
//                     mobNumber: res.data.mobile,

//                 }, () => {
//                     if (this.state.role.indexOf("driver") >= 0) {
//                         var entity = "driver";
//                         var myJSON = JSON.stringify(entity);
//                         this.setState({
//                             entity: myJSON,
//                             userdetails: res.data
//                         }, () => {
//                             console.log("this.state.entity driver===>", this.state.entity);
//                         })
//                     } else {
//                         var entity = "employee";
//                         var myJSON = JSON.stringify(entity);
//                         this.setState({
//                             entity: myJSON,
//                             userdetails: res.data
//                         }, () => {
//                             console.log("this.state.entity emp===>", this.state.entity);
//                         })
//                     }
//                 })
//             })
//             .catch((error) => { });
//     }
// 	handleSubmit(event) {
// 		if ($('#editUser').valid()) {
// 			var userid = this.state.UserId;
// 			var formvalues = {
// 				"firstname": this.refs.firstName.value,
// 				"lastname": this.refs.lastName.value,
// 				"mobNumber": (this.state.mobNumber).replace("-", ""),

// 			}
// 			axios.patch('/api/users/patch/' + userid, formvalues)
// 				.then((response) => {
// 						this.updatePerson();
// 				})
// 				.catch((error) => {
// 					// window.location = '/dashboard';
// 				});
// 		}
// 	}
// 	backtoum() {
// 		window.location = ("/umlistofusers")
// 	}

// 	handleChange(event) {
// 		const target = event.target.value;
// 		const name = event.target.name;
// 		this.setState({
// 			[name]: target,
// 		}, () => {
// 		})
// 	}

// 	render() {
// 		const searchOptions = {
// 			types: ['(cities)'],
// 			componentRestrictions: { country: "in" }
// 		}

// 		return (
// 			<div className="container-fluid">
// 				<div className="row">
// 					<div className="formWrapper">
// 						<section className="content">
// 							<div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 pageContent ">
// 								<div className="col-lg-10 col-md-10 col-xs-12 col-sm-12 contactdeilsmg pageHeader">
// 									Edit User Data
// 								</div>
// 								<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 " id="rolemodalcl">
// 									<button type="button" className="btn col-lg-12 col-md-12 col-sm-12 col-xs-12 addexamform userbtn clickforhideshow"
// 										onClick={this.backtoum.bind(this)}>
// 										<i className="fa fa-undo" aria-hidden="true"></i>
// 										<b>&nbsp;&nbsp; Back To UM List</b>
// 									</button>
// 								</div>
// 								<hr className="hr-head container-fluid row" />
// 								<div className="box-body">
// 									<div className="row">
// 										<form id="editUser">
// 											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
// 												{/* <PersonMaster userdetails={this.state.userdetails} userdets={this.state.user} entity="employee" /> */}
// 											</div>

// 											 <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12">
// 												<div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">
// 													<div className="form-margin col-lg-6 col-md-6 col-xs-6 col-sm-6">
// 														<label className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">First Name <label className="requiredsign">*</label></label>
// 														<div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding" id="firstNameErr">
// 															<input type="text" style={{ textTransform: 'capitalize' }}
// 																className="form-control"
// 																id="firstName" ref="firstName" name="firstName" value={this.state.firstName} onChange={this.handleChange} placeholder="First Name" />
// 														</div>
// 													</div>
// 													<div className="form-margin col-lg-6 col-md-6 col-xs-6 col-sm-6">
// 														<label className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">Last Name <label className="requiredsign">*</label></label>
// 														<div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding" id="lastNameErr">
// 															<input type="text" className="form-control"
// 																id="lastName" ref="lastName" name="lastName" value={this.state.lastName} onChange={this.handleChange} placeholder="Last Name" />
// 														</div>
// 													</div>
// 												</div>
// 												<div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">
// 													<div className="form-margin col-lg-6 col-md-6 col-xs-6 col-sm-6">
// 														<label className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">Username/Email <label className="requiredsign">*</label></label>
// 														<div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding" id="usernameErr">
// 															<input type="text" disabled value={this.state.username} onChange={this.handleChange} className="form-control" ref="username" name="username" required />
// 														</div>
// 													</div>
// 													<div className="form-margin col-lg-6 col-sm-6 col-xs-6 col-md-6">
// 														<label className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">Mobile Number <label className="requiredsign">*</label></label>
// 														<div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding" id="mobNumberErr">
// 															<PhoneInput
// 																country={'in'}
// 																value={this.state.mobNumber}
// 																name="mobNumber"
// 																inputProps={{
// 																	name: 'mobNumber',
// 																	required: true
// 																}}
// 																onChange={mobNumber => { this.setState({ mobNumber }) }}
// 															/>
// 														</div>

// 													</div>

// 												</div>
// 												<div className="form-margin col-lg-12 col-sm-12 col-xs-12 col-md-12 pull-right">
// 													<button onClick={this.handleSubmit.bind(this)} className="col-lg-2 col-sm-2 col-xs-2 col-md-2 btn resetBtn pull-right">Update</button>
// 												</div>
// 											</div>

// 										</form>

// 									</div>

// 								</div>
// 							</div>
// 						</section>
// 					</div>
// 				</div>
// 			</div>
// 		);
// 	}
// }

// export default EditUserProfile;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const UserProfileForm = () => {
//   const [userData, setUserData] = useState({
//     firstname: '',
//     lastname: '',
//     email: '',
//     mobile: '',
//   });

//   const fetchData = async (userId) => {
//     try {
//       const response = await axios.get(`/api/users/get/${userId}`);
//       setUserData(response.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   useEffect(() => {
//     const userId = window.location.pathname.split('/').pop();
//     fetchData(userId);
//   }, []);

//   const handleUpdate = async () => {
//     try {
//       const response = await axios.patch(`/api/users/patch/${userData._id}`, userData);
//       console.log('User data updated:', response.data);
//     } catch (error) {
//       console.error('Error updating data:', error);
//     }
//   };

// //   return (
// //     <div className="mx-auto max-w-md p-6 bg-gray-100">
// //       <form className="space-y-4">
// //         <div>
// //           <label className="block">
// //             First Name *:
// //             <input
// //               type="text"
// //               value={userData.firstname}
// //               onChange={(e) => setUserData({ ...userData, firstname: e.target.value })}
// //               className="form-input mt-1 block w-full"
// //             />
// //           </label>
// //         </div>
// //         <div>
// //           <label className="block">
// //             Last Name *:
// //             <input
// //               type="text"
// //               value={userData.lastname}
// //               onChange={(e) => setUserData({ ...userData, lastname: e.target.value })}
// //               className="form-input mt-1 block w-full"
// //             />
// //           </label>
// //         </div>
// //         <div>
// //           <label className="block">
// //             Email *:
// //             <input
// //               type="text"
// //               value={userData.email}
// //               onChange={(e) => setUserData({ ...userData, email: e.target.value })}
// //               className="form-input mt-1 block w-full"
// //             />
// //           </label>
// //         </div>
// //         <div>
// //           <label className="block">
// //             Mobile Number *:
// //             <input
// //               type="text"
// //               value={userData.mobile}
// //               onChange={(e) => setUserData({ ...userData, mobile: e.target.value })}
// //               className="form-input mt-1 block w-full"
// //             />
// //           </label>
// //         </div>
// //         <button type="button" onClick={handleUpdate} className="bg-blue-500 text-white py-2 px-4 rounded">
// //           Update
// //         </button>
// //       </form>
// //     </div>
// //   );

//   return (
// 	<div className="container-fluid">
// 		<div className="row">
// 			<div className="formWrapper">
// 				<section className="content">
// 					<div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 pageContent ">
// 						<div className="col-lg-10 col-md-10 col-xs-12 col-sm-12 contactdeilsmg pageHeader">
// 							Edit User Data
// 						</div>
// 						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 " id="rolemodalcl">
// 							<button type="button" className="btn col-lg-12 col-md-12 col-sm-12 col-xs-12 addexamform userbtn clickforhideshow"
// 								onClick={this.backtoum.bind(this)}>
// 								<i className="fa fa-undo" aria-hidden="true"></i>
// 								<b>&nbsp;&nbsp; Back To UM List</b>
// 							</button>
// 						</div>
// 						<hr className="hr-head container-fluid row" />
// 						<div className="box-body">
// 							<div className="row">
// 								<form id="editUser">
// 									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
// 										{/* <PersonMaster userdetails={this.state.userdetails} userdets={this.state.user} entity="employee" /> */}
// 									</div>

// 									 <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12">
// 										<div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">
// 											<div className="form-margin col-lg-6 col-md-6 col-xs-6 col-sm-6">
// 												<label className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">First Name <label className="requiredsign">*</label></label>
// 												<div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding" id="firstNameErr">
// 													<input type="text" style={{ textTransform: 'capitalize' }}
// 														className="form-control"
// 														id="firstName" ref="firstName" name="firstName" value={this.state.firstName} onChange={this.handleChange} placeholder="First Name" />
// 												</div>
// 											</div>
// 											<div className="form-margin col-lg-6 col-md-6 col-xs-6 col-sm-6">
// 												<label className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">Last Name <label className="requiredsign">*</label></label>
// 												<div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding" id="lastNameErr">
// 													<input type="text" className="form-control"
// 														id="lastName" ref="lastName" name="lastName" value={this.state.lastName} onChange={this.handleChange} placeholder="Last Name" />
// 												</div>
// 											</div>
// 										</div>
// 										<div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">
// 											<div className="form-margin col-lg-6 col-md-6 col-xs-6 col-sm-6">
// 												<label className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">Username/Email <label className="requiredsign">*</label></label>
// 												<div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding" id="usernameErr">
// 													<input type="text" disabled value={this.state.username} onChange={this.handleChange} className="form-control" ref="username" name="username" required />
// 												</div>
// 											</div>
// 											<div className="form-margin col-lg-6 col-sm-6 col-xs-6 col-md-6">
// 												<label className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">Mobile Number <label className="requiredsign">*</label></label>
// 												<div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding" id="mobNumberErr">
// 													<PhoneInput
// 														country={'in'}
// 														value={this.state.mobNumber}
// 														name="mobNumber"
// 														inputProps={{
// 															name: 'mobNumber',
// 															required: true
// 														}}
// 														onChange={mobNumber => { this.setState({ mobNumber }) }}
// 													/>
// 												</div>

// 											</div>

// 										</div>
// 										<div className="form-margin col-lg-12 col-sm-12 col-xs-12 col-md-12 pull-right">
// 											<button onClick={this.handleSubmit.bind(this)} className="col-lg-2 col-sm-2 col-xs-2 col-md-2 btn resetBtn pull-right">Update</button>
// 										</div>
// 									</div>

// 								</form>

// 							</div>

// 						</div>
// 					</div>
// 				</section>
// 			</div>
// 		</div>
// 	</div>
// );
// };

// export default UserProfileForm;

import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
// import { useHistory } from 'react-router-dom';

const UserProfileForm = () => {
  // const history = useHistory();

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobNumber: "",
    _id: "", // Assuming user ID is present in the response
  });

  const fetchData = async (userId) => {
    try {
      const response = await axios.get(`/api/users/get/${userId}`);
      setUserData(response.data);
      console.log("response", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const userId = window.location.pathname.split("/").pop();
    fetchData(userId);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(userData);
    try {
      const response = await axios.patch(
        `/api/users/patch/${userData._id}`,
        userData
      );
      if (response.data === "USER_UPDATED") {
        swal("Success ", "User Updated successfully");
      }
      console.log("User data updated:", response.data);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  //   const redirectToUMList = () => {
  //     history.push('/umlistofusers');
  //   };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="formWrapper">
          <section className="content">
            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 pageContent ">
              <div className="col-lg-10 col-md-10 col-xs-12 col-sm-12 contactdeilsmg pageHeader">
                Edit User Data
              </div>
              <div
                className="col-lg-2 col-md-2 col-sm-12 col-xs-12 "
                id="rolemodalcl"
              >
                <button
                  type="button"
                  className="btn col-lg-12 col-md-12 col-sm-12 col-xs-12 addexamform userbtn clickforhideshow"
                  onClick={() => window.history.back()}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i>
                  <b>&nbsp;&nbsp; Back To UM List</b>
                </button>
              </div>
              <hr className="hr-head container-fluid row" />
              <div className="box-body">
                <div className="row">
                  <form id="editUser">
                    <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12">
                      <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">
                        <div className="form-margin col-lg-6 col-md-6 col-xs-6 col-sm-6">
                          <label className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">
                            First Name <label className="requiredsign">*</label>
                          </label>
                          <div
                            className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding"
                            id="firstNameErr"
                          >
                            <input
                              type="text"
                              style={{ textTransform: "capitalize" }}
                              className="form-control"
                              id="firstName"
                              name="firstName"
                              value={userData.firstname}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  firstname: e.target.value,
                                })
                              }
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                        <div className="form-margin col-lg-6 col-md-6 col-xs-6 col-sm-6">
                          <label className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">
                            Last Name <label className="requiredsign">*</label>
                          </label>
                          <div
                            className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding"
                            id="lastNameErr"
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="lastName"
                              name="lastName"
                              value={userData.lastname}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  lastname: e.target.value,
                                })
                              }
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">
                        <div className="form-margin col-lg-6 col-md-6 col-xs-6 col-sm-6">
                          <label className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">
                            Username/Email{" "}
                            <label className="requiredsign">*</label>
                          </label>
                          <div
                            className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding"
                            id="usernameErr"
                          >
                            <input
                              type="text"
                              disabled
                              value={userData.email}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  email: e.target.value,
                                })
                              }
                              className="form-control"
                              name="email"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-margin col-lg-6 col-sm-6 col-xs-6 col-md-6">
                          <label className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding">
                            Mobile <label className="requiredsign">*</label>
                          </label>
                          <div
                            className="col-lg-12 col-sm-12 col-xs-12 col-md-12 NOpadding"
                            id="mobNumberErr"
                          >
                            <input
                              type="text"
                              value={userData.mobile}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  mobile: e.target.value,
                                })
                              }
                              className="form-control"
                              name="mobNumber"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-margin col-lg-12 col-sm-12 col-xs-12 col-md-12 pull-right"
                        style={{ marginTop: "14px" }}
                      >
                        <button
                          onClick={handleSubmit}
                          className="col-lg-2 col-sm-2 col-xs-2 col-md-2 btn resetBtn pull-right"
                        >
                          Update User
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default UserProfileForm;
