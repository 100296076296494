import React, {Component} from 'react';
import {render}           from 'react-dom';
import $                  from "jquery";
import axios              from 'axios';
import { useNavigate } from "react-router-dom";
import BusinessCategory   from  '../../admin2.0/Master/BusinessCategory/BusinessCategory.js';
import withRouter from '../common/withRouter.js';
import BusinessExpertise  from  '../../admin2.0/Master/BusinessExpertise/BusinessExpertise.js';

import '../../admin2.0/companysetting/css/CompanySetting.css';

 class MasterData extends Component{
    constructor(props) {
		super(props)

		this.state = {
			companyinformation				: "Company Information",
      // profileCreated            : false,
      editType                  : "",
      editId                    : "",
      oneFieldEditId            : ""
		}
	
	}
  componentDidMount() {
    console.log("this.props",this.props)
    if(this.props){
      if(this.props.params.editId && this.props.params.editId !== 'undefined'){
        this.setState({editId : this.props.params.editId},
                      ()=>{
                        console.log("project componentDidMount editId = ",this.state.editId);
                      });
      }

      if(this.props.params.oneFieldEditId && typeof this.props.params.oneFieldEditId !== 'undefined'){
        this.setState({oneFieldEditId : this.props.params.oneFieldEditId},
                      ()=>{
                        console.log("project componentDidMount oneFieldEditId = ",this.state.oneFieldEditId);
                      });

      }
    }


  }
 
  componentDidUpdate(prevProps) {
    console.log("this.props",this.props)
    if(this.props.params.editId !== this.state.editId){
      this.setState({editId : this.props.params.editId},
                    ()=>{
                      //console.log("global componentDidUpdate editId = ",this.state.editId);
                    });
    }
    if(this.props.params.oneFieldEditId !== this.state.oneFieldEditId){
      this.setState({oneFieldEditId : this.props.params.oneFieldEditId},
                    ()=>{
                      // console.log("project componentDidUpdate oneFieldEditId = ",this.state.oneFieldEditId);
                    });
    }
  }

  tab(event){
    this.props.navigate('/project-master-data')
  }

   render() {
    
      return (
         <div className="container-fluid">
            <div className="row">
               <div className="formWrapper">
                  <section className="content">
                     <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 pageContent ">
                        <div className="row">
                           <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 titleaddcontact NOpadding">
                             <div className="box-header with-border col-lg-12 col-md-12 col-xs-12 col-sm-12 NOpadding-right ">
                                 <h4 className="weighttitle col-lg-11 col-md-11 col-xs-11 col-sm-11 NOpadding-right">Master Data</h4>
                             </div>
                           </div>     
                           <div className="boxMinHeight boxMinHeighttab addMarginTop">
                              <div className="col-lg-3 col-md-3 col-xs-12 col-sm-12 noPadding"> 
                                 <ul className="nav nav-tabs tabs-left sideways">
                                    <li className="active col-lg-12 col-md-12 col-xs-12 col-sm-12"><a className="tabLeft lettersp tablefthr" href="#BusinessCategory" data-toggle="tab"  onClick={this.tab.bind(this)}>   Business Category   </a></li>
                                 </ul>   
                              </div>
                              <div className="tab-content col-lg-9 col-md-9 col-xs-12 col-sm-12">   
                                 <div className="tab-pane active" id="BusinessCategory">
                                   <BusinessCategory editId={this.state.editId} oneFieldEditId={this.state.oneFieldEditId} history={this.props.navigate}/>
                                 </div>
                              </div> 
                           </div>
                        </div>
                     </div>
                  </section>
            </div>
         </div>
      </div>
      );
   }
}


function WithNavigate(props) {
    let navigate = useNavigate();
    return <MasterData {...props} navigate={navigate} />
}

export default withRouter(WithNavigate);
