import React, { Component } from 'react';
import { connect }        from 'react-redux';

import $                    from 'jquery';
import jQuery               from 'jquery';
import axios                from 'axios';
import swal                 from 'sweetalert';
import withRouter           from '../common/withRouter.js';
import _                    from 'underscore';
import "./Dashboard.css"

import 'bootstrap/js/tab.js';
var apiLink = "";
class Dashboard extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            "user_ID"   : "",
            "startRange": 0,
            "limitRange": 10,
            "editId"    :  '',
            "fieldValue" : ""
        };
    }
    componentDidMount() {
        var userDetails = localStorage.getItem('userDetails');
        var userData = JSON.parse(userDetails);
      
        // Fetch category count
        axios.get('/api/businesscategory/get/count')
          .then(categoryResponse => {
            const categoryCount = categoryResponse.data.count;
      
            // Fetch subcategory count
            axios.get('/api/business-subcategory/get/count')
              .then(subcategoryResponse => {
                const subcategoryCount = subcategoryResponse.data.count;
      
                // Fetch product count
                axios.get('/api/products/get/count')
                  .then(productResponse => {
                    const productCount = productResponse.data.count;
      
                    // Fetch order count
                    axios.get('/api/order-product/get/count')
                      .then(orderResponse => {
                        const orderCount = orderResponse.data.count;
      
                        this.setState({
                          testimonialsCount: categoryCount,
                          subcategoriesCount: subcategoryCount,
                          productsCount: productCount,
                          ordersCount: orderCount
                        });
                      })
                      .catch(orderError => {
                        console.error('Error fetching order count:', orderError);
                      });
                  })
                  .catch(productError => {
                    console.error('Error fetching product count:', productError);
                  });
              })
              .catch(subcategoryError => {
                console.error('Error fetching subcategory count:', subcategoryError);
              });
          })
          .catch(categoryError => {
            console.error('Error fetching category count:', categoryError);
          });
      }
      
      

    
   

    render() {
        

        return (
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding">
              <section className="content">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pageContent">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 NOPadding topMargin box-header with-border">
                        <h4 className="col-lg-12 col-md-12 col-xs-12 col-sm-12 weighttitle NOPadding">
                          Dashboard
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class=" ">
                    <div class="container1">
                    <div class="row">
                      
                      <div class="col-md-4">
                        <div class="testimonials-box">
                          <div class="testimonials-heading">
                            <span class="testimonials-title">Categories</span>
                            <div class="testimonials-button">
                              <a href="/project-master-data" class="btn btn-primary btn-sm">
                                <span className='addicon'>+</span> Add
                              </a>
                            </div>
                          </div>
          
                          <div class="testimonials-stats">
                          <div class="testimonials-count-box">
                          {/* <div class="testimonials-count">{this.state.testimonialsCount}</div> */}
                          <div class="testimonials-count">
          {this.state.testimonialsCount < 1 ? 0 : this.state.testimonialsCount}
        </div>
                          </div>
                          </div>
                        </div>
                        
                      </div>
                      <div class="col-md-4">
                        <div class="testimonials-box">
                          <div class="testimonials-heading">
                            <span class="testimonials-title">Subcategories</span>
                            <div class="testimonials-button">
                              <a href="/project-master-data" class="btn btn-primary btn-sm">
                                {/* <i class="fas fa-plus text-blue-900"></i> Add */}
                                <span className='addicon'>+</span> Add
                              </a>
                            </div>
                          </div>
          
                          <div class="testimonials-stats">
                          <div class="testimonials-count-box">
                          {/* <div class="testimonials-count">{this.state.subcategoriesCount}</div> */}
                          <div class="testimonials-count">
          {this.state.subcategoriesCount < 1 ? 0 : this.state.subcategoriesCount}
        </div>
                          </div>
                          </div>
                        </div>
                        
                      </div>
                      <div class="col-md-4">
                        <div class="testimonials-box">
                          <div class="testimonials-heading">
                            <span class="testimonials-title">Products</span>
                            <div class="testimonials-button">
                              <a href="/product-upload" class="btn btn-primary btn-sm">
                                {/* <i class="fas fa-plus text-blue-900"></i> Add */}
                                <span className='addicon'>+</span> Add
                              </a>
                            </div>
                          </div>
          
                          <div class="testimonials-stats">
                          <div class="testimonials-count-box">
                          {/* <div class="testimonials-count">{this.state.productsCount}</div> */}
                          <div class="testimonials-count">
          {this.state.productsCount < 1 ? 0 : this.state.productsCount}
        </div>
                          </div>
                          </div>
                        </div>
                        
                      </div>
                      <div class="col-md-4">
                        <div class="testimonials-box">
                          <div class="testimonials-heading">
                            <span class="testimonials-title">Orders</span>
                            <div class="testimonials-button">
                              <a href="/order-status" class="btn btn-primary btn-sm">
                                {/* <i class="fas fa-plus text-blue-900"></i> Add */}
                                <span className='addicon'>+</span> Add
                              </a>
                            </div>
                          </div>
          
                          <div class="testimonials-stats">
                          <div class="testimonials-count-box">
                          {/* <div class="testimonials-count">{this.state.ordersCount}</div> */}
                          <div class="testimonials-count">
          {this.state.ordersCount < 1 ? 0 : this.state.ordersCount}
        </div>
                          </div>
                          </div>
                        </div>
                        
                      </div>
                      <div class="col-md-4">
                          <div class="profile-box">
                              <div class="profile-heading">Order Status</div>
                              <div class="profile-content">
                                  <a href="/order-status" class="btn btn-primary btn-m">Add Order Status</a>
                              </div>
                          </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          );
          

    }
}




const mapStateToProps = (state)=>{
  console.log(" state on Dashboard ==> ",state)
  return {
    userDetails   : state.userDetails,
  }
};


const mapDispatchToProps = (dispatch)=>{
  return {
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));

