import React, { Component } from "react";
import $ from "jquery";
import IAssureTable from "../IAssureTable/IAssureTable.jsx";
import axios from "axios";
import "font-awesome/css/font-awesome.min.css";


class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id                               : "product_List", // Title or Heading of report
      tableName                        : "Product_List", // Title or Heading of report
      tableDatas                       : [],
      reportData                       : {},
      tableData                        : [],
      startRange                       : 0,
      limitRange                       : 10,
      apiLink                          : "/api/products",
      twoLevelHeader                   : false,
      deleteMethod                     : "delete",
       
      tableHeading  : {       
        name                          : "Product Name",
        productId                     : "Product Id",
        description                   : "Description",
        businessCategory              : "Category",
        businessSubCategory           : "SubCategory",
        actions                       : 'Actions',
      },
      
      tableObjects: {
        editUrl: "/product-upload",
        apiLink: "/api/products",
        paginationApply: true,
        searchApply: true,
        downloadApply: true,
        deleteMethod:"/product-upload"
        
      },
     
    };
    window.scrollTo(0, 0);
  }
componentDidMount() {
    this.getData();
  }
  getData() {
    var formValue = {
        startRange: 0,
        limitRange: 10,
    };
    axios
      .post(this.state.apiLink + "/get/list", formValue)
      .then((response) => {
        var tableData = response.data.map((a, i) => {
          console.log(i," tableData => ",a);
          return {
            _id: a._id,
            name: a.name,
            productId: a.productId,
            description: a.description,
            businessCategory: a.businessCategory,
            businessSubCategory: a.businessSubCategory
          };
        });
        this.setState(
          {
            tableData : tableData,
          },
          () => {
            console.log("tableData => ",this.state.tableData)
          },
        );
      })
      .catch((error) => {});

    axios
      .get(this.state.apiLink + "/get/count", formValue)
      .then((response) => {
        this.setState(
          {
            dataCount: response.data.count,
          },
          () => {},
        );
      })
      .catch((error) => {});
  }
  render() {
    // console.log(" this.state = ", this.state);
    return (
      
      <IAssureTable
        tableHeading={this.state.tableHeading}
        twoLevelHeader={this.state.twoLevelHeader}
        dataCount={this.state.dataCount}
        tableData={this.state.tableData}
        getData={this.getData.bind(this)}
        tableObjects={this.state.tableObjects}
        deleteMethod={this.state.deleteMethod} 
        // editId ={this.state.editId}
              
      />
     
    
    );
  }
}

export default ProductList;








// // productUpload.js
// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import IAssureTable from "../IAssureTable/IAssureTable.jsx"; // Make sure to adjust the path to IAssureTable

// export default () => {
//   const { product_id } = useParams();
//   const [editingProduct, setEditingProduct] = useState("");
//   const [catgArr, setCatgArr] = useState([]);
//   // ... (rest of your state variables)
  
//   const [id, setId] = useState("product_List");
//   const [tableName, setTableName] = useState("Product_List");
//   const [tableDatas, setTableDatas] = useState([]);
//   const [reportData, setReportData] = useState({});
//   const [tableData, setTableData] = useState([]);
//   const [startRange, setStartRange] = useState(0);
//   const [limitRange, setLimitRange] = useState(10);
//   const [apiLink, setApiLink] = useState("/api/products");
//   const [twoLevelHeader, setTwoLevelHeader] = useState(false);
//   const [deleteMethod, setDeleteMethod] = useState("delete");
//   const [dataCount, setDataCount] = useState(0); // Added for dataCount
  
//   const tableHeading = {       
//     name: "Product Name",
//     productId: "Product Id",
//     description: "Description",
//     businessCategory: "Category",
//     businessSubCategory: "SubCategory",
//     actions: 'Actions',
//   };
  
//   const tableObjects = {
//     editUrl: "/product-upload",
//     apiLink: "/api/products",
//     paginationApply: true,
//     searchApply: true,
//     downloadApply: true,
//     deleteMethod: "/product-upload"
//   };
  
//   // ... (rest of your useEffect hooks)
  
//   const getData = () => {
//     const formValue = {
//       startRange: 0,
//       limitRange: 10,
//     };
//     axios
//       .post(apiLink + "/get/list", formValue)
//       .then((response) => {
//         const tableData = response.data.map((a, i) => ({
//           _id: a._id,
//           name: a.name,
//           productId: a.productId,
//           description: a.description,
//           businessCategory: a.businessCategory,
//           businessSubCategory: a.businessSubCategory,
//         }));
//         setTableData(tableData); // Update state directly
//       })
//       .catch((error) => {});

//     axios
//       .get(apiLink + "/get/count", formValue)
//       .then((response) => {
//         setDataCount(response.data.count); // Update dataCount state
//       })
//       .catch((error) => {});
//   };
  
//   // ... (rest of your functions)
  
//   // ... (rest of your JSX)
  
//   return (
//     <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding">
//       <section className="content">
//         {/* ... (rest of your JSX) */}
        
//         {/* ProductList code */}
//         <div>
//           <IAssureTable
//             tableHeading={tableHeading}
//             twoLevelHeader={twoLevelHeader}
//             dataCount={dataCount} // Pass dataCount
//             tableData={tableData}
//             getData={getData}
//             tableObjects={tableObjects}
//             deleteMethod={deleteMethod}
//           />
//         </div>
//       </section>
//     </div>
//   );
// };
