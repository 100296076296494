import React, { Component }                       from 'react';
import { connect }                                from 'react-redux';
import withRouter                                 from './admin2.0/common/withRouter.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';


// Section: 1 - SystemSecurity ******************************************************
import Login            from './admin2.0/systemSecurity/Login.js';
import ConfirmOtp       from './admin2.0/systemSecurity/ConfirmOtp.js';
import ForgotPassword   from './admin2.0/systemSecurity/ForgotPassword.js';
import ResetPassword    from './admin2.0/systemSecurity/ResetPassword.js';
import SignUp           from './admin2.0/systemSecurity/SignUp.js';

import Header           from './admin2.0/common/header/Header.js'; 
import Footer           from './admin2.0/common/footer/Footer.js';
import Leftsidebar      from './admin2.0/common/leftSidebar/Leftsidebar.js';
import CoreLayout       from './admin2.0/CoreLayout/CoreLayout.js';

// ============= Master =======================
import MasterData       from "./admin2.0/MasterData/MasterData.js";

// ============= Reports =======================
// import ConsultantDataReport                  from "./admin2.0/Reports/1_consultantDataReport.js";
// import ConsultantIntroCallsReport            from "./admin2.0/Reports/2_consultantIntroCalls";
// import ProfileEditingReport                  from './admin2.0/Reports/3_ProfileEditingReport.js';
// import EnterpriseListingReprt                from './admin2.0/Reports/4_EnterpriseListingReport.js';
// import LeadsGeneratedReport                  from './admin2.0/Reports/5_LeadsGeneratedReport.js';
// import ReviewRatingReport                    from './admin2.0/Reports/6_ReviewRatingReport.js';
// import ConsultantComplaintReport             from './admin2.0/Reports/7_ConsultantComplaintReport.js';
// import ConsultantSettlementReport            from './admin2.0/Reports/8_ConsultantSettlementReport.js';
// import ListingFeesReport                     from './admin2.0/Reports/9_ListingFeesReport.js';
// import FeedbackReport                        from './admin2.0/Reports/10_FeedbackReport.js';
// import UserDataReport                        from './admin2.0/Reports/11_UserDataReport.js';
// import UserIntroductoryCallsReport           from './admin2.0/Reports/12_UserIntroductoryCallsReport.js';
// import UserComplaintsReport                  from './admin2.0/Reports/13_UserComplaintsReport.js';

// ==============================================
// import ConsultantProfileApprovalTable        from './admin2.0/ProfileApprovalTable/ConsultantProfileApprovalTable.js'
// import EnterpriseProfileApprovalTable        from './admin2.0/ProfileApprovalTable/EnterpriseProfileApprovalTable'
// import ConsultantSubCategoryApproval         from './admin2.0/CategoryApprovalTable/ConsultantSubCategoryApproval.js';
// import ConsultantExpertiseCategoryApproval   from './admin2.0/CategoryApprovalTable/ConsultantExpertiseCategoryApproval.js';
// import EnterpriseExpertiseCategoryApproval   from './admin2.0/CategoryApprovalTable/EnterpriseExpertiseCategoryApproval.js';
// import EnterpriseSubCategoryApproval         from './admin2.0/CategoryApprovalTable/EnterpriseSubCategoryApproval.js';
import ProductUpload                         from "./admin2.0/ProductUpload/ProductUpload.js";
// import ProductList                           from "./admin2.0/ProductList/ProductList.js";
import OrderStatus                           from "./admin2.0/OrderStatus/OrderStatus.js";
// import OrderList                             from './admin2.0/OrderList/OrderList.js';


class Layout extends Component  {

    constructor(props) {
        super();
        this.state = {
            loggedIn: false,
        }
    }

    componentDidMount() {
        const token = localStorage.getItem("token");
        if (token !== null && token !== "undefined") {
            this.setState({
                loggedIn: true
            })
        } else { }

    }

    logout() {
        var token = localStorage.removeItem("token");
        if (token !== null && token !== "undefined") {
            this.setState({
                loggedIn: false
            })
        }
    }

    render() {
      if(this.state.loggedIn) {
         return (
            <Router>
               <div className="hold-transition skin-blue fixed sidebar-mini">
                  <div className="content-wrapper">
                     <div className="wrapper">
                        <Header />
                        <div className="">
                             <div className="row">
                                 <Leftsidebar/>
                                 <div className="container-fluid main-container">
                                     <div className="row">
                                         <div className="dashboardWrapper" >
                                             <div className="backColor col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                                <CoreLayout />
                                                <Routes >
                                                     {/* Master Data */}

                                                     <Route path="/project-master-data"          element={<MasterData render={(props) => ({ ...props })}/> } exact />
                                                     <Route path="/project-master-data/:editId"  element={<MasterData render={(props) => ({ ...props })}/> } exact />
                                                     <Route path="/project-master-data/oneField/:oneFieldEditId" element={<MasterData render={(props) => ({ ...props })}/> } exact />
                                                    
                                                     {/*<Route path="/project-master-data"          render={(props)=><MasterData {...props}/> } exact />
                                                     <Route path="/project-master-data/:editId"  render={(props)=><MasterData {...props}/> } exact />
                                                     <Route path="/project-master-data/oneField/:oneFieldEditId" render={(props)=><MasterData {...props}/> } exact />
                                                   */}
                                                    {/* <Route path="/profile_approval/consultant"          element={<ConsultantProfileApprovalTable/>} exact /> */}
                                                    {/* <Route path="/profile_approval/enterprise"          element={<EnterpriseProfileApprovalTable/>} exact />
                                                    <Route path="/other_sub_catg/enterprise"            element={<EnterpriseSubCategoryApproval/>} exact />
                                                    <Route path="/other_enterprise_catg/enterprise"     element={<EnterpriseExpertiseCategoryApproval/>} exact />
                                                    <Route path="/other_sub_catg/consultant"            element={<ConsultantSubCategoryApproval/>} exact />
                                                    <Route path="/other_expertise_catg/consultant"      element={<ConsultantExpertiseCategoryApproval/>} exact /> */}
                                                    
                                                    <Route path="/product-upload"                       element={<ProductUpload />} exact />
                                                    <Route path="/product-upload/:product_id"           element={<ProductUpload />} exact />
                                                    {/* <Route path="/product-list"                         element={<ProductList />} exact /> */}
                                                    <Route path="/order-status"                         element={<OrderStatus />} exact />
                                                    <Route path="/order-status/:orderProductId"         element={<OrderStatus />} exact />
                                                    {/* <Route path="/order-list"                           element={<OrderList />} exact /> */}
                                                    {/* Reports */}
                                                    {/* <Route path="/reports/consultant-data"              element={<ConsultantDataReport /> } exact /> */}
                                                 </Routes>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                        </div>
                     </div>
                  </div>
                  <Footer />
               </div>
            </Router>
         );
      } else {
         return (
            <div>
               <Router >
                     <Routes >
                         <Route path="/" exact strict element={<Login/>} />
                         <Route path="/login" exact strict element={<Login/>} />
                         <Route path="/signup" exact strict element={<SignUp/>} />
                         <Route path="/forgotpassword" exact strict element={<ForgotPassword/>} />
                         <Route path="/reset-pwd/:user_ID" exact strict element={<ResetPassword/>} />
                         <Route path="/confirm-otp/:userID" exact strict element={<ConfirmOtp/>} />
                     </Routes>
               </Router>
            </div>
         );
      }
   }
}

 
export default Layout;